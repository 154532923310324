import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import styles from "@aspen/theme/Venture.module.less";
import { convertUSD2USDC, validAmount, i18nUtil, systemDetection } from "@aspen/libs";
import type { IVentureListItem } from "@aspen/model";
import { BRAND } from "@aspen/model";
import { getFundIntentionAmount, getVentureIntentionAmount } from "@aspen/services";
import { NumericInput } from "@aspen/ui";

interface IProps {
  selectVenture: IVentureListItem | null;
  setSubmitDisabled: (submitDisabled: boolean) => void;
  // InnerVentureSell展示内容在venture deal fund基本相同。用productType来区分展示类型
  productType?: "venture" | "fund";
}

const InnerVentureSell = (props: IProps, ref) => {
  const { selectVenture, setSubmitDisabled, productType = "venture" } = props;
  const intl = i18nUtil.t();
  const system = systemDetection();
  const [submittedAmount, setSubmittedAmount] = useState<string>();
  const [listAmount, setListAmount] = useState<string>("");
  const [errorListMsg, setErrorListMsg] = useState<string>("");
  const [discountRate, setDiscountRate] = useState<[number, number]>([0, 200]);
  // 将 state 对象添加到引用中
  useImperativeHandle(ref, () => ({
    listAmount,
    discountRate: `${discountRate[0]}% ~ ${discountRate[1]}%`
  }));

  useEffect(() => {
    if (selectVenture?.id) {
      fetchVentureIntentionAmount(selectVenture?.id);
    }
    setListAmount("");
    setErrorListMsg("");
  }, [selectVenture?.id]);

  const fetchVentureIntentionAmount = (ventureId: string) => {
    if (!ventureId) {
      return;
    }
    let method = system === BRAND ? getVentureIntentionAmount : getFundIntentionAmount;
    method(ventureId).then((res) => {
      if (res?.code == "0") {
        setSubmittedAmount(res?.data);
      }
    });
  };

  const handleChangeListAmount = useCallback(
    (_value: string) => {
      const value = validAmount(_value);
      if (value === false) return;
      let error = "";
      const maxListAmount = Number(selectVenture?.amount) - Number(submittedAmount ?? 0);
      if (maxListAmount < Number(value)) {
        error = i18nUtil.formatMessage(
          { id: "ventures.shares.list.amount.error" },
          { balance: `${maxListAmount} ${selectVenture?.currency ?? ""}` }
        );
      }
      setListAmount(value);
      setErrorListMsg(error);
      setSubmitDisabled && setSubmitDisabled(!(selectVenture && value && error == ""));
    },
    [selectVenture?.id, submittedAmount]
  );

  return (
    <>
      {selectVenture ? (
        <>
          <div className={styles.flexRow}>
            <span className={styles.keyText}>{intl["ventures.shares.select.title"]}</span>
            <span className={styles.valueText}>
              {intl[
                productType === "venture"
                  ? `venture.capital.${selectVenture?.type}`
                  : `fund.capital.${selectVenture?.type}`
              ] ?? "--"}
            </span>
          </div>
          <div className={styles.flexRow}>
            <span className={styles.keyText}>{intl["ventures.shares.balance"]}</span>
            <span className={styles.valueText}>
              {selectVenture?.amount} {selectVenture?.currency ?? ""}
            </span>
          </div>
          {system === BRAND ? (
            <div
              dangerouslySetInnerHTML={{
                __html: i18nUtil.formatMessage(
                  { id: "ventures.shares.balance.submitted" },
                  {
                    balance: `${submittedAmount ?? "--"}  ${convertUSD2USDC(selectVenture?.currency ?? "")}`
                  }
                )
              }}
            />
          ) : null}
        </>
      ) : null}
      <div className={styles.flexColumn}>
        <span className={styles.inputTitleText}>{intl["ventures.shares.list.amount"]}</span>
        <NumericInput
          bordered={false}
          value={listAmount}
          placeholder={intl["ventures.shares.input.placeholder"]}
          className={styles.amountInput}
          onChangeEvent={handleChangeListAmount}
          addonAfter={convertUSD2USDC(selectVenture?.currency ?? "")}
        />
        <p className={styles.errorMsg}>{errorListMsg}</p>
      </div>
      {/*目前市场行情比较好，销售不希望向用户传达Venture可以用Discount（折扣）申购或出售，隐藏理想贴现率*/}
      {/*<div className={styles.flexColumn}>*/}
      {/*    <span className={styles.inputTitleText}>{intl["ventures.shares.discount.rate"]}</span>*/}
      {/*    <VentureSlider*/}
      {/*        key={selectVenture?.id ? "selectVenture" : "null"}*/}
      {/*        defaultValue={discountRate}*/}
      {/*        onAfterChange={setDiscountRate}*/}
      {/*    />*/}
      {/*</div>*/}
    </>
  );
};

const areEqual = (prevProps: IProps, nextProps: IProps) => {
  return prevProps.selectVenture?.id === nextProps.selectVenture?.id;
};

export default React.memo(forwardRef(InnerVentureSell), areEqual);
