/*
 * NOTE:
 * 1. 新增/改变路由，需要在增加/检查权限配置
 */
import {
  USER_ROLE,
  HOME_PATH,
  LOGIN_PATHS,
  TRADE_PATH,
  YIELD_PATHS,
  VENTURES_PATHS,
  INTELLIGENCE_PATHS,
  PROFILE_PATHS,
  WALLET_PATHS,
  HISTORY_PATHS,
  ASSETS_PATHS,
  POLICY_PATH,
  AGREEMENT_PATH,
  AUTHORIZATION_AGREEMENT_PATH,
  VIP_PATHS,
  STRUCTURED_PATH,
  DOWNLOAD_BILL_PATH,
  EVENTS_PATHS,
  FUNDS_PATHS
} from "@aspen/libs";

const {
  ROLE_CUSTOMER,
  ROLE_PARTNER_CUSTOMER,
  ROLE_MANAGER_CUSTOMER,
  ROLE_MANAGER_READONLY,
  ROLE_SUB
} = USER_ROLE;

interface IRoleItem {
  exact: boolean;
  showHeader?: boolean; // 是否展示顶部导航, 临时方案，新闻详情不需要登陆可访问，但是也需要展示顶部导航菜单，其他事是needAuthenticated判断的，后续考虑优化 next todo
  needAuthenticated?: boolean; // 是否需要登陆权限, 默认false
  role?: string[]; // 可以访问的角色列表, 不设置或者为空，即不限角色， 只有在needAuthenticate为true的情况下才需要配置role
  titleKey?: string; // 路由的title
  titleDescription?: string; // 路由的title description
}
export type IRole = {
  [propName: string]: IRoleItem;
};

/*
 * desc： 路由/角色/配置
 * 不配置路由权限或者为空，即不限角色role
 * 只有在needAuthenticate为true的情况下才需要配置role
 *
 * 根据域名判断，登陆路由模块是否存在 （域名中存在partner时为代理商CMS登陆, 存在service时为SAS登陆 仅显示登陆login路由 本地调试代理商的话需要partner改为local）
 */

const loginRouteConfig: IRole = {
  [LOGIN_PATHS.LOGIN]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.login",
    titleDescription: ""
  },
  [LOGIN_PATHS.LOGIN_REGISTER]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.register",
    titleDescription: ""
  },
  [LOGIN_PATHS.LOGIN_REGISTER_MULTI]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.register.multi",
    titleDescription: ""
  },
  [LOGIN_PATHS.LOGIN_RESET]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.login.reset",
    titleDescription: ""
  },
  [POLICY_PATH]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.policy",
    titleDescription: ""
  },
  [AGREEMENT_PATH]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.agreement",
    titleDescription: ""
  }
};

const afterLoginRouteConfig: IRole = {
  "/": {
    exact: true,
    needAuthenticated: true,
    role: [ROLE_CUSTOMER]
  },
  [HOME_PATH]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true,
    titleKey: "router.title.home",
    titleDescription: ""
  },
  [TRADE_PATH]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true
  },
  [YIELD_PATHS.YIELD]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.structured",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED_DUAL_SUBSCRIBE]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
    needAuthenticated: true,
    titleKey: "router.title.dualSubscribe.subscribe",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED_ACCUMULATOR_SUBSCRIBE]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
    needAuthenticated: true,
    titleKey: "router.title.accumuSubscribe.subscribe",
    titleDescription: ""
  },
  [STRUCTURED_PATH.STRUCTURED_SNOWBALL_SUBSCRIBE]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.snowballSubscribe.subscribe",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_MY_INVESTMENT_PLAN]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.my.investment.plan",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_ADD_INVESTMENT_PLAN]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.add.investment.plan",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_CREATE_DCA_PLAN]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.add.investment.plan",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_INVESTMENT_HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.investment.history",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_SUBSCRIBE_FIXED_YIELD]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.yield.subscribe.fixed",
    titleDescription: ""
  },
  [YIELD_PATHS.YIELD_APP_BTC_YTD]: {
    exact: true,
    role: [],
    needAuthenticated: false,
    titleKey: "router.title.yield.subscribe.fixed",
    titleDescription: ""
  },
  [FUNDS_PATHS.FUNDS]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.strategies",
    titleDescription: ""
  },
  [FUNDS_PATHS.FUNDS_DETAIL]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.strategies.detail",
    titleDescription: ""
  },
  [VENTURES_PATHS.VENTURES]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.ventures",
    titleDescription: ""
  },
  [VENTURES_PATHS.VENTURES_DETAIL]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.ventures.detail",
    titleDescription: ""
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_INSIGHTS]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true,
    titleKey: "router.title.intelligence.insights",
    titleDescription: ""
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_NEWS]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true,
    titleKey: "router.title.intelligence.news",
    titleDescription: ""
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_NEWS_DETAIL]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: false
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true,
    titleKey: "router.title.intelligence.research",
    titleDescription: ""
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH_DETAIL]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: false
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_BRIEFING]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true,
    titleKey: "router.title.intelligence.market.daily",
    titleDescription: ""
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_MARKET_DAILY_DETAIL]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: false
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_NOTICE_DETAIL]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: false
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true,
    titleKey: "router.title.intelligence.expert",
    titleDescription: ""
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_LIST]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true,
    titleKey: "router.title.intelligence.expert",
    titleDescription: ""
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_DETAIL]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: false
  },
  [INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT_AUTHOR_DETAIL]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    needAuthenticated: true,
    titleKey: "router.title.intelligence.expert",
    titleDescription: ""
  },
  [PROFILE_PATHS.PROFILE]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true,
    titleKey: "router.title.profile",
    titleDescription: ""
  },
  [PROFILE_PATHS.PROFILE_MY_INVITATION]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true,
    titleKey: "router.title.profile.my.invitation",
    titleDescription: ""
  },
  [PROFILE_PATHS.PROFILE_KYC_VERIFICATION]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
    needAuthenticated: true,
    titleKey: "router.title.profile.kyc.certify",
    titleDescription: ""
  },
  [PROFILE_PATHS.PROFILE_KYC_RESULT]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
    needAuthenticated: true,
    titleKey: "router.title.profile.kyc.certify",
    titleDescription: ""
  },
  [PROFILE_PATHS.PROFILE_AGENT_AUTHORIZE]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true,
    titleKey: "router.title.profile.agent.authorize",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_DEPOSIT]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    needAuthenticated: true,
    titleKey: "router.title.wallet.deposit",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_WITHDRAW]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    needAuthenticated: true,
    titleKey: "router.title.wallet.withdraw",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_DEPOSIT_OTC]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.deposit.otc",
    titleDescription: ""
  },
  // FIXME: 暂时移除circle相关入口
  // [WALLET_PATHS.WALLET_ADDACCOUNT]: {
  //   exact: true,
  //   role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
  //   needAuthenticated: true,
  //   titleKey: "router.title.wallet.add.account",
  //   titleDescription: ""
  // },
  [WALLET_PATHS.WALLET_WITHDRAW_USDC]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    needAuthenticated: true,
    titleKey: "router.title.wallet.withdraw.usdc",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_HISTORY]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    needAuthenticated: true,
    titleKey: "router.title.wallet.withdraw.whitelist.history",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_MANAGEMENT]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    needAuthenticated: true,
    titleKey: "router.title.wallet.withdraw.whitelist.management",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_WITHDRAW_ADD_ADDRESS]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    needAuthenticated: true,
    titleKey: "router.title.wallet.withdraw.whitelist.add.address",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_DEPOSIT_USDC]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB, ROLE_MANAGER_CUSTOMER, ROLE_MANAGER_READONLY],
    needAuthenticated: true,
    titleKey: "router.title.wallet.deposit.usdc",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_HISTORY]: {
    exact: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    needAuthenticated: true,
    titleKey: "router.title.wallet.history",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_AUMFEE]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.aum.fee",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VAULT]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.vault",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VAULT_STORAGE]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true,
    titleKey: "router.title.wallet.vault.storage",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VAULT_WITHDRAW]: {
    exact: true,
    role: [ROLE_CUSTOMER],
    needAuthenticated: true,
    titleKey: "router.title.wallet.vault.withdraw",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VAULT_HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.vault.history",
    titleDescription: ""
  },
  [HISTORY_PATHS.HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.history",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_ACCOUNT_HISTORY]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.history.account.detail",
    titleDescription: ""
  },
  [WALLET_PATHS.WALLET_VENTURE_UPDATES_DETAIL]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.wallet.venture.updates",
    titleDescription: ""
  },
  [HISTORY_PATHS.HISTORY_APPLICATION_DETAIL]: {
    exact: true,
    role: [ROLE_SUB],
    needAuthenticated: true
  },
  [AUTHORIZATION_AGREEMENT_PATH]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.authorization.agreement",
    titleDescription: ""
  },
  [ASSETS_PATHS.ASSETS]: {
    exact: true,
    role: [
      ROLE_CUSTOMER,
      ROLE_SUB,
      ROLE_PARTNER_CUSTOMER,
      ROLE_MANAGER_CUSTOMER,
      ROLE_MANAGER_READONLY
    ],
    needAuthenticated: true,
    titleKey: "router.title.assets",
    titleDescription: ""
  },
  [ASSETS_PATHS.ASSETS_MOBILE]: {
    exact: true,
    needAuthenticated: false,
    role: [],
    titleKey: "router.title.assets.mobile",
    titleDescription: ""
  },
  [VIP_PATHS.VIP]: {
    exact: true,
    needAuthenticated: true,
    role: [ROLE_CUSTOMER],
    titleKey: "router.title.vip",
    titleDescription: ""
  },
  [PROFILE_PATHS.PROFILE_KYC_APPUPLOAD]: {
    exact: true,
    role: [],
    needAuthenticated: false,
    titleKey: "router.title.profile.kyc.certify",
    titleDescription: ""
  },
  [DOWNLOAD_BILL_PATH]: {
    exact: true,
    needAuthenticated: true,
    role: [ROLE_CUSTOMER, ROLE_SUB],
    titleKey: "router.title.download.bill",
    titleDescription: ""
  },
  [EVENTS_PATHS.EVENT_LIST]: {
    exact: true,
    needAuthenticated: true,
    role: [ROLE_CUSTOMER],
    titleKey: "event.title",
    titleDescription: ""
  },
  [EVENTS_PATHS.EVENTS_DETAIL]: {
    exact: true,
    needAuthenticated: false,
    role: [ROLE_CUSTOMER],
    showHeader: true,
    titleKey: "event.title",
    titleDescription: ""
  }
};

export const routeConfig = Object.assign(loginRouteConfig, afterLoginRouteConfig);
