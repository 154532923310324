import dynamic from "next/dynamic";
import styles from "@aspen/theme/Home.module.less";
import React from "react";

export const ModalCompoundAutoInvest = dynamic(() => import("./ModalCompoundAutoInvest"), {
  ssr: false
});

export const ModalCompoundDca = dynamic(() => import("./ModalCompoundDca"), {
  ssr: false
});

export const ModalRedeemAutoInvest = dynamic(() => import("./ModalRedeemAutoInvest"), {
  ssr: false
});

export const ModalSafetyVerification = dynamic(
  () => import("../modalSafetyVerification/ModalSafetyVerification"),
  {
    ssr: false
  }
);

export const ModalSubscribe = dynamic(() => import("./ModalSubscribe"), {
  ssr: false
});

export const ModalRedeem = dynamic(() => import("./ModalRedeem"), {
  ssr: false
});

export const ModalAutoSubscribe = dynamic(() => import("./ModalAutoSubscribe"), {
  ssr: false
});

export const ModalAutoPurchase = dynamic(() => import("./ModalAutoPurchase"), {
  ssr: false
});

export const SavingsTable = dynamic(() => import("./SavingsTableList"), {
  loading: () => <div className={`${styles.savingsList} middleAlign`}>{/* <Spin /> */}</div>,
  ssr: false
});
export const SavingsTableTabs = dynamic(() => import("./SavingsTableTabs"), {
  ssr: false
});

export const FixedYield = dynamic(() => import("./FixedYield"), {
  loading: () => <div className={`${styles.savingsList} middleAlign`}>{/* <Spin /> */}</div>
});

export const NewFixedYield = dynamic(() => import("./NewFixedYield"), {
  loading: () => <div className={`${styles.savingsList} middleAlign`}>{/* <Spin /> */}</div>
});

export const CacheFlexibleSavingsTable = dynamic(() => import("./CacheFlexibleSavingsTable"), {
  loading: () => <div className={`${styles.savingsList} middleAlign`}>{/* <Spin /> */}</div>,
  ssr: false
});

export const StakingYield = dynamic(() => import("./StakingYield"), {
  loading: () => <div className={`${styles.savingsList} middleAlign`}>{/* <Spin /> */}</div>
});

export const ModalAutomaticInvestment = dynamic(() => import("./ModalAutomaticInvestment"), {
  ssr: false
});

export const ModalDcaCoinList = dynamic(() => import("./ModalDcaCoinList"), {
  ssr: false
});
export const ModalDcaStrategy = dynamic(() => import("./ModalDcaStrategy"), {
  ssr: false
});
export const ModalDcaConfirm = dynamic(() => import("./ModalDcaConfirm"), {
  ssr: false
});
export const InvestmentPlan = dynamic(() => import("./InvestmentPlan"), {
  ssr: false
});
export const BtcChart = dynamic(() => import("./BtcChart"), {
  ssr: false
});
export const ModalDcaLimitTip = dynamic(() => import("./ModalDcaLimitTip"), {
  ssr: false
});
