import React from "react";
import Bugsnag from "@bugsnag/js";
import { Alert } from "antd";
import { bugsnagInit, env } from "@aspen/libs";

interface IProps {
  children: React.ReactNode;
  bugsnagDisable?: boolean;
}
interface IState {
  hasError: boolean;
  onLine: boolean;
}
let ErrorBoundary;
let uploadBugsnag;

// 异常上报
class WithLogging extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    const { bugsnagDisable } = this.props;
    this.state = {
      hasError: false,
      onLine: true
    };

    //非生产模式,绿环境,不能使用bugsnag(operation项目)三种情况下不使用bugsnag
    // green环境不上报逻辑在bugsnagInit内部处理，避免vercel打包报错
    uploadBugsnag = process.env.NODE_ENV == "production" && !bugsnagDisable;
    if (uploadBugsnag) {
      bugsnagInit();
      // @ts-ignore
      ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
    }
  }
  componentDidMount() {
    window.addEventListener("offline", () => {
      this.setState({
        onLine: false
      });
    });
    window.addEventListener("online", () => {
      this.setState({
        onLine: true
      });
    });
    // Error log statistic
    window.addEventListener("error", function onError(e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      // Ignore ResizeObserver error
      console.error("window.addEventListener error:", e);
      if (e.message === "ResizeObserver loop limit exceeded") {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    });
  }
  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染可以显降级 UI
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (String(error).includes("Loading") && String(error).includes("chunk")) {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  ErrorView = () => (
    <div>
      <p>Something went wrong.</p>
    </div>
  );

  render(): React.ReactNode {
    if (uploadBugsnag) {
      return (
        <ErrorBoundary FallbackComponent={this.ErrorView}>
          {!this.state.onLine && (
            <Alert
              style={{ textAlign: "center" }}
              showIcon
              message="No network, please check the network link! 😢"
              type="warning"
              closable
            />
          )}
          {this.props.children}
        </ErrorBoundary>
      );
    }
    return (
      <>
        {!this.state.onLine && (
          <Alert
            style={{ textAlign: "center" }}
            showIcon
            message="No network, please check the network link! 😢"
            type="warning"
            closable
          />
        )}
        {this.state.hasError ? (
          <>
            <h1 style={{ textAlign: "center", marginTop: "10%" }}>
              Something went wrong, Please reload again.
            </h1>
            {this.props.children}
          </>
        ) : null}
        {this.props.children}
      </>
    );
  }
}

export { WithLogging };
